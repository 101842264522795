import React from "react";
import { Link } from "gatsby";
import { Link as ScrollLink } from "react-scroll";
const NavLinks = () => {
  const handleDropdownStatus = (e) => {
    let clickedItem = e.currentTarget.parentNode;
    clickedItem.querySelector(".dropdown-list").classList.toggle("show");
  };
  return (
    <ul className="main-nav__navigation-box one-page-scroll-menu">
      <li>
          <ScrollLink
              activeClass="current"
              to="home"
              spy={true}
              smooth={true}
              offset={-150}
              duration={500}
          >
              Home
          </ScrollLink>
      </li>
      <li>
        <ScrollLink
          activeClass="current"
          to="features"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Features
        </ScrollLink>
      </li>
      <li>
        <ScrollLink
          activeClass="current"
          to="testimonials"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Reviews
        </ScrollLink>
      </li>
      <li>
    <ScrollLink
          activeClass="current"
          to="screens"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Screenshots
        </ScrollLink>
      </li>
      <li>
          <ScrollLink
              activeClass="current"
              to="developer"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
          >
            Developer
        </ScrollLink>
    </li>
    </ul>
  );
};

export default NavLinks;
