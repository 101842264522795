import React from "react";
import Layout from "@components/layout";
import Header from "@components/header/header";
import Banner from "@components/banner";
import Video from "@components/video";
import AppFeature from "@components/app-feature";
import CallToAction from "@components/call-to-action";
import TestimonialsHome from "@components/testimonials/testimonials-home";
import AppShot from "@components/app-shot";
import ServicesHome from "@components/services/services-home";
import Footer from "@components/footer";
import MobileNav from "@components/mobile-nav";
import Developer from "@components/developer";

const HomeOne = () => {
    return (
        <Layout pageTitle="Itinerate - travel planner">
            <Header/>
            <Banner/>
            <ServicesHome/>
            <CallToAction/>
            <Video/>
            <AppFeature/>
            <TestimonialsHome/>
            <AppShot/>
            <Developer/>
            <Footer/>
            <MobileNav/>
        </Layout>
    );
};

export default HomeOne;
