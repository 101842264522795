import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import developerImage from "@images/developer/sean.png";
import { DEVELOPER_DATA } from "@data";
import {Link} from "gatsby";

const Developer = () => {
  const { sectionTitle, paragraph, linkedIn, stackoverflow, email } = DEVELOPER_DATA;
  return (
    <section className="developer">
      <Container>
        <Row>
          <Col xl={6} lg={6}>
            <div
              className="cat_one_moc wow fadeInUp"
              data-wow-duration="1500ms"
            >
              <img src={developerImage} alt="Developer Image" className="developer_image" width={374} />
            </div>
          </Col>
          <Col xl={6} lg={6}>
            <div className="cat_one_content">
              <div className="block-title">
                <p>{sectionTitle.text}</p>
                <h3>{sectionTitle.title}</h3>
              </div>
              <div className="cta-one__text">
                <p>{paragraph}</p>
              </div>
              <div className="developer_links">
                <div>
                  <Link to={linkedIn.url}>
                    <img src={linkedIn.icon} alt="Linkedin Icon" width={36} height={36} className="icon"/>
                    <span className="link">{linkedIn.text}</span>
                  </Link>
                </div>
                <div className="link-with-icon">
                  <Link to={stackoverflow.url}>
                    <img src={stackoverflow.icon} alt="Stack Overflow Icon" width={36} height={36} className="icon"/>
                    <span className="link">{stackoverflow.text}</span>
                  </Link>
                </div>
                <div className="link-with-icon-bottom">
                  <Link to={email.email}>
                    <img src={email.icon} alt="Email icon" width={36} height={36} className="icon"/>
                    <span className="link">{email.text}</span>
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Developer;
