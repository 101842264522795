import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import bgShape1 from "@images/banner/banner-shape-1-1.png";
import bgShape2 from "@images/banner/banner-shape-1-2.png";
import bannerBG from "@images/banner/banner-bg-1.png";
import bannerShape1 from "@images/shapes/banner-1-1.png";
import bannerShape2 from "@images/banner/banner-shape-1-4.png";
import timelineImage from "@images/app-shots/timeline.png";
import iconMapPin from "@images/banner/icon_map_and_pin.png";
import iconRoadSunset from "@images/banner/icon_road_sunset.png";
import iconMountain from "@images/banner/icon_mountain_pin.png";
import {URLS} from "../data";

const Banner = () => {
  return (
    <section className="banner-one" id="home">
      <img src={bgShape1} alt="Banner-Shape-1" className="banner-shape-1" />
      <img src={bgShape2} alt="Banner-Shape-2" className="banner-shape-2" />
      <div
        className="banner-one__bg"
        style={{ backgroundImage: `url(${bannerBG})` }}
      ></div>
      <Container>
        <Row>
          <Col xl={7} lg={6}>
            <div className="banner-one__content">
              <h3>
                Plan and visualize meaningful travel experiences
              </h3>
              <p>
                Itinerate - your new travel planner and companion
              </p>
              <div className="banner__button_one">
                <a href={URLS.appStoreLink} className="thm-btn">
                  <span>Download App</span>
                </a>
              </div>
            </div>
          </Col>
          <Col xl={5} lg={6}>
            <div className="banner-img wow fadeInUp" data-wow-duration="1500ms">
              <img src={bannerShape1} className="banner-image__curvs" alt="" />
              <div
                className="banner-bg"
                style={{ backgroundImage: `url(${bannerShape2})` }}
              ></div>
              <img src={timelineImage} alt="Banner-img"  width={374} height={808}  />
              <div className="banner-icon-1">
                <img src={iconMapPin} alt="" width={80} height={80}  />
              </div>
              <div className="banner-icon-2">
                <img src={iconRoadSunset} alt="" width={80} height={80}/>
              </div>
              <div className="banner-icon-3">
                <img src={iconMountain} alt=""width ={80} height={80}/>
              </div>
            </div>

            <div className="banner__button_two">
              <a href={URLS.appStoreLink} className="thm-btn">
                <span>Download App</span>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Banner;
